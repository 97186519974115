/* LINKS */

a {
	color: $color-link;
	text-decoration: none;

	@include mq(mobile) {
		text-decoration: underline;
	}

	&[href^="tel:"],
	&[href^="mailto:"] {
		@include mq(mobile) {
			text-decoration: none;
		}
	}

	&:active {
		background-color: transparent;
	}

	&:hover {
		color: $color-link-hover;
	}

	&:active,
	&:focus {
		color: $color-link-active;
		border: 0 none;
		outline: 0 none;
	}
}

/* GENERAL BUTTONS */

.btn {
	@extend %nofocus, %noselect;

	border: none;
	cursor: pointer;
	outline: none;

	&.btn-primary {
		align-items: center;
		background: $color-buttons;
		border-radius: $border-radius;
		color: $color-white;
		display: flex;
		font-family: $font-secondary;
		font-size: $text-size-button;
		font-weight: $font-weight-medium;
		height: 45px;
		justify-content: center;
		max-width: 151px;
		padding: $padding-button;
		position: relative;
		transition: all 0.1s ease;
		width: 100%;
		z-index: 0;

		@include mq(tablet-down) {
			font-size: $font-size-med;
			max-width: 135px;
		}

		@include mq(mobile) {
			text-decoration: none;
		}

		&:hover,
		&:focus {
			background: highlight-by($color-buttons, 5%);
		}

		&:focus {
			@extend %accessible-focus-btn;
		}

		&:disabled,
		&[disabled] {
			background: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			border: 1px solid $color-buttons;
			color: $color-white;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
				background: $color-buttons;
			}
		}

		&.mod-icon {
			svg,
			img {
				margin-left: 12px;
				transition: transform 0.3s ease;
			}

			&:hover {
				svg,
				img {
					transform: translateX(3px);
				}
			}
		}

		&.mod-link {
			padding: 0;
			background: transparent;
			color: $color-buttons;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons;
				background: transparent;
				box-shadow: none;
			}

			@include mq(mobile) {
				padding: 0;
				display: inline-block;
				text-align: unset;
			}
		}
	}
}
