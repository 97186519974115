/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: rgba(#768692, 0.1);
$color-neutral-dark: #768692;

$color-primary: #00a9ce;
$color-primary-alt: #00a7cc;
$color-secondary: #101820;

$color-text-dark: $color-secondary;
$color-text: #303030;
$color-text-light: #959595;

$color-headings: $color-text-dark;

$color-error: #ff0000;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-buttons: $color-link;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

$color-borders: #d8d8d8;
$color-borders-light: rgba($color-borders, 0.57);

/* FONTS */
$font-standard: "lato", sans-serif, Arial, Helvetica;
$font-secondary: "Karla", sans-serif, Arial, Helvetica;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-xsmall: 12 / $font-base * 1rem;
$font-size-small: 13 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-med-large: 17 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 19 / $font-base * 1rem;
$font-size-xxlarge: 20 / $font-base * 1rem;
$font-size-xxxlarge: 27 / $font-base * 1rem;
$font-size-xxxxlarge: 30 / $font-base * 1rem;

$font-size-h1: 35 / $font-base * 1rem;
$font-size-h2: 32 / $font-base * 1rem;
$font-size-h3: 23 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 20 / $font-base * 1rem;
$font-size-hero-med: 40 / $font-base * 1rem;
$font-size-hero-large: 60 / $font-base * 1rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-regular;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-large: 2;

$border-radius: 4px;
$padding-button: 0 10px;
$text-size-button: $font-size-large;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 95%;
$container-max-width: 1280px;
$container-max-width-small: 1110px;
$container-max-width-large: 1300px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 30px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 768px;
