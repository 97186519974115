#product {
	@import "partials/section-banner";
	@import "partials/section-tabs";
	@import "partials/section-accordion";
	@import "partials/section-cards";
	@import "partials/section-breadcrumbs";

	@include mq(mobile) {
		overflow-x: hidden;
	}

	.section-product {
		padding: 50px 0;

		@include mq(tablet-down) {
			overflow: hidden;
			padding: 20px 0;
		}

		&:before {
			@include mq(tablet-down) {
				background-size: contain;
				display: block;
				height: 360px;
				max-width: 175px;
				min-height: auto;
				right: -15px;
				top: 80px;
			}
		}

		.sub-content {
			@extend %container, %flex-row;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			h1.product-title {
				@extend %common-heading;

				margin-bottom: 40px;

				&:after {
					content: none;
				}

				&.mod-desktop {
					@include mq(tablet-down) {
						display: none;
					}
				}

				&.mod-mobile {
					display: none;

					@include mq(tablet-down) {
						display: flex;
						line-height: 42px;
					}
				}
			}

			& > .copy {
				margin-right: 210px;
				max-width: 502px;
				padding-right: 20px;
				width: 100%;

				@include mq(tablet-down) {
					order: 1;
					margin-right: 0;
					max-width: none;
					padding-bottom: 40px;
					padding-right: 0;
				}

				p,
				ul li {
					color: $color-text;
					font-size: $font-size-large;
					font-weight: $font-weight-regular;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
					}
				}

				ul {
					margin-left: 0;
					padding: 0;
				}

				.btns {
					align-items: center;
					display: flex;
					flex-wrap: wrap;
					margin-top: 40px;

					@include mq(mobile) {
						flex-direction: column;
					}

					&.mod-base .btn {
						border: none;
						color: $color-white;
						display: block;
						font-size: $font-size-standard;
						max-width: 210px;
					}

					.btn {
						align-items: center;
						border-top: 1px solid $color-borders-light;
						color: $color-black;
						display: flex;
						flex: 0 0 calc(50% - 20px);
						font-size: $font-size-large;
						height: 50px;
						line-height: 18px;
						margin-right: 40px;
						max-width: none;
						transition: all 0.3s ease;

						@include mq(tablet-down) {
							border-bottom: 1px solid $color-borders-light;
							flex: 1;
						}

						@include mq(mobile) {
							border-bottom: none;
							max-width: none !important;
							margin-right: 0;
							width: 100%;
						}

						&:hover {
							color: $color-primary-alt;

							img {
								transform: translateX(4px);
							}
						}

						&:last-child,
						&:nth-last-child(2) {
							border-bottom: 1px solid $color-borders-light;

							@include mq(mobile) {
								border-bottom: none;
							}
						}

						&:nth-child(even) {
							margin-right: 0;

							@include mq(tablet) {
								margin-right: 40px;
							}
						}

						&:last-child {
							margin-right: 0;

							@include mq(mobile) {
								border-bottom: 1px solid $color-borders-light;
							}
						}

						img {
							margin-right: 27px;
							transition: transform 0.3s ease;
						}
					}
				}
			}

			.product-image {
				flex: 1;
				max-height: 542px;
				max-width: 568px;
				position: relative;
				width: 100%;

				@include mq(tablet-down) {
					order: 0;
				}

				@include mq(mobile) {
					max-width: none;
					margin-bottom: 20px;
				}

				.image {
					img {
						max-width: 100%;

						@include mq(mobile) {
							max-height: 330px;
						}
					}
				}

				.brand-logo {
					position: absolute;
					right: 70px;
					bottom: 40px;

					img {
						@include mq(tablet-down) {
							max-width: 70px;
						}
					}
				}

				.poi-circle {
					position: absolute;

					@include mq(tablet-down) {
						display: none;
					}

					.circle {
						align-items: center;
						background: rgba($color-primary, 0.41);
						border-radius: 100px;
						cursor: pointer;
						display: flex;
						height: 27px;
						justify-content: center;
						transition: width, height, transform 0.25s;
						width: 27px;
						z-index: 99;

						@include mq(mobile) {
							height: 20px;
							width: 20px;
						}

						&:before {
							background-color: $color-primary;
							border-radius: 10px;
							box-shadow: 0 2px 15px 2px rgba(0, 0, 0, 0.09);
							content: "";
							height: 7px;
							position: absolute;
							transition: all 0.25s;
							width: 7px;

							@include mq(mobile) {
								height: 5px;
								width: 5px;
							}
						}
					}

					&:hover,
					&.mod-active {
						.circle {
							transform: matrix(1.75, 0, 0, 1.75, 0, 0);
						}

						.box {
							opacity: 1;
							z-index: 99;
						}
					}

					& > .box {
						align-items: center;
						background: $color-white;
						border-radius: $border-radius * 2;
						box-shadow: 0 2px 15px 2px rgba($color-black, 0.09);
						display: flex;
						justify-content: center;
						left: 35px;
						min-height: 60px;
						min-width: 260px;
						opacity: 0;
						padding: 10px 20px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						transition: z-index 0.01, opacity 0.25s ease;
						z-index: -1;

						@include mq(tablet-down) {
							top: -2px;
							min-height: 31px;
							min-width: 196px;
						}

						&:before {
							content: "";
							background: $color-white;
							display: block;
							height: 12px;
							left: -6px;
							position: absolute;
							transform: rotate(45deg);
							width: 12px;

							@include mq(tablet-down) {
								left: -3px;
							}
						}

						& > span {
							color: $color-text;
							font-size: $font-size-larger;
							font-weight: $font-weight-regular;

							@include mq(tablet-down) {
								font-size: $font-size-standard;
							}
						}
					}
				}
			}
		}
	}

	.section-banner {
		.sub-content .banner-items .item .image {
			max-width: 38px;
			min-width: auto;
		}
	}

	.section-process {
		background-color: $color-neutral-dark;
		color: $color-white;

		padding: 85px 0 110px;

		@include mq(tablet-down) {
			padding: 30px 0 70px;
		}

		.sub-content {
			@extend %container, %flex-row;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			h3 {
				@extend %common-heading-smaller;

				color: $color-white;
				font-weight: $font-weight-regular;
				margin-bottom: 30px;

				&:after {
					background-color: $color-white;
				}
			}

			.order-process {
				margin-right: 130px;

				@include mq(tablet-down) {
					margin-right: 0;
				}

				p,
				ul > li {
					font-size: $font-size-large;
					font-weight: $font-weight-regular;
					line-height: 32px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 30px;
					}
				}

				ul {
					@extend %list-default;

					margin-top: 50px;

					@include mq(tablet-down) {
						margin-top: 30px;
					}

					li {
						background-image: url("../assets/images/check.png");
						background-position: 0px 7px;
						background-repeat: no-repeat;
						font-size: $font-size-larger;
						line-height: 33px;
						margin-bottom: 20px;
						padding-left: 35px;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 30px;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}

			.aside-download {
				max-width: 421px;
				width: 100%;

				@include mq(tablet-down) {
					margin-top: 40px;
					max-width: none;
				}

				p {
					font-size: $font-size-large;
					line-height: 34px;
				}

				ul.list-downloads {
					@extend %list-default;

					margin-top: 15px;

					.item-download {
						@extend %flex-align-center;

						background-color: $color-white;
						border-radius: $border-radius;
						min-height: 60px;
						margin-bottom: 6px;
						padding: 0 16px;
						position: relative;

						&:last-child {
							margin-bottom: 0;
						}

						.download-link {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}

						.icon {
							@extend %flex-align-center;

							margin-right: 19px;

							img {
								@include mq(tablet-down) {
									width: 15px;
								}
							}
						}

						.download-title {
							color: $color-text;
							font-size: $font-size-larger;
							font-weight: $font-weight-semibold;
							line-height: 32px;

							@include mq(tablet-down) {
								font-size: $font-size-med-large;
								line-height: 20px;
							}
						}

						.download-filesize {
							color: #b1b1b1;
							font-size: $font-size-small-med;
							line-height: 12px;
							margin-left: auto;
						}
					}
				}
			}
		}
	}

	.section-faqs-and-testimonials {
		background-color: $color-neutral;
		padding: 95px 0 155px;

		@include mq(tablet-down) {
			padding: 40px 0 65px;
		}

		.sub-content {
			@extend %container, %flex-row;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			h3.title {
				@extend %common-heading;

				margin-bottom: 40px;
			}

			.faqs {
				flex: 1;
				margin-right: 93px;

				@include mq(tablet-down) {
					margin-right: 0;
				}
			}

			.aside-testimonials {
				@extend %aside-testimonials;
			}
		}
	}

	.section-cards {
		@extend %swish-3;

		background-color: $color-primary;
		overflow: hidden;
	}
}
