form {
	.sub-errors {
		color: $color-error;
		display: none;
		margin-bottom: 20px;

		.sub-error {
			font-size: $font-size-med;
		}

		span {
			display: block;
			margin-bottom: 3px;
		}
	}

	.sub-error {
		color: $color-validation-error;
		position: absolute;
		right: 0;
		top: 3px;
		z-index: 99;

		&.mod-spam {
			color: $color-validation-error;
			display: block;
			position: static;
			text-align: right;
			margin-bottom: 10px;
		}
	}

	.form-element {
		display: inline-block;
		margin: 0 0 20px;
		position: relative;
		width: 100%;
		z-index: 1;

		&.mod-error {
			input,
			textarea {
				border: 1px solid $color-validation-error;
				box-shadow: inset 0 0 0 1px $color-validation-error;

				&:focus {
					box-shadow: inset 0 0 0 1px $color-validation-error,
						0 0 0 4px rgba(164, 12, 12, 0.13);
				}
			}

			label {
				color: $color-validation-error;
			}
		}

		&:not(.mod-error) {
			input,
			textarea {
				&:focus {
					border-color: $color-primary-alt;
					outline: $color-primary 2px outset;

					@include mq(mobile) {
						@include placeholder {
							color: $color-primary-alt;
						}
					}
				}
			}
		}

		input,
		textarea {
			background-color: $color-white;
			border-radius: $border-radius;
			border: none;
			color: $color-text;
			display: block;
			font-size: $font-size-med;
			height: 50px;
			outline: none;
			padding: 0 20px;
			position: relative;
			width: 100%;

			@include mq(mobile) {
				padding: 0 10px;

				@include placeholder {
					color: $color-text;
				}
			}

			@include autocomplete-fill {
				background-color: $color-white;
				box-shadow: 0 0 0px 1000px $color-white inset;
				-webkit-text-fill-color: $color-text;
				transition: background-color 5000s ease-in-out 0s;
			}

			@include placeholder {
				color: $color-white;
			}
		}

		label {
			display: block;
			font-size: $font-size-large;
			font-weight: $font-weight-regular;
			margin-bottom: 15px;
			padding: 0;
			pointer-events: none;
			width: 100%;

			@include mq(tablet-down) {
				font-size: $font-size-med;
				margin-bottom: 5px;
			}
		}

		textarea {
			padding-top: 10px;
			vertical-align: top;
		}
	}

	.btn.btn-primary {
		margin: 15px 0 0;
		padding: 10px;

		@include mq(mobile) {
			padding: 15px 0;
			max-width: none;
			width: 100%;
			margin: 20px 0 0;
		}
	}
}

input,
select,
textarea,
optgroup {
	font-family: $font-standard !important;
}

input[name="Form_Email"] {
	display: none;
	opacity: 0;
	visibility: hidden;
	height: 0;
}
