#accordion {
	@extend %list-default;

	.accordion-item {
		background-color: $color-white;
		border-radius: $border-radius;
		margin-bottom: 5px;
		min-height: 65px;
		position: relative;

		@include mq(tablet-down) {
			margin-bottom: 10px;
			min-height: 60px;
		}

		h3 {
			margin-bottom: 0;
		}

		button {
			@extend %flex-align-center;

			background: none;
			border: none;
			color: $color-text;
			display: flex;
			cursor: pointer;
			font-size: $font-size-larger;
			line-height: 24px;
			min-height: 65px;
			outline: none;
			padding: 0 70px 0 17px;
			position: relative;
			text-align: left;
			width: 100%;

			@include mq(tablet-down) {
				font-size: $font-size-med;
				padding: 10px 50px 10px 15px;
				text-align: left;
			}

			svg.arrow-right {
				color: $color-primary-alt;
				height: 14px;
				margin-left: auto;
				pointer-events: none;
				position: absolute;
				right: 23px;
				transition: transform 0.3s ease;
				width: 8px;
			}

			&.active {
				color: $color-secondary;

				svg {
					transform: rotate(90deg);
					transition: transform 0.3s ease;
				}
			}
		}
	}

	.accordion-toggle {
		display: block;
	}

	.accordion-content {
		max-height: 0;
		overflow: hidden;
		padding: 0 18px;
		transition: max-height 0.3s ease;

		&.active {
			max-height: 10000px;
			padding: 10px 18px 95px 18px;
			transition: max-height 0.3s ease;

			@include mq(tablet-down) {
				padding-bottom: 30px;
			}

			& + .accordion-focus {
				&:before {
					opacity: 1;
					visibility: visible;
					z-index: 1;
				}
			}
		}

		p {
			font-size: $font-size-larger;
			line-height: 33px;

			@include mq(tablet-down) {
				font-size: $font-size-med;
				line-height: 30px;
			}
		}
	}

	.accordion-focus {
		@extend %accessible-focus-btn;

		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;

		&:before {
			bottom: -3px;
			top: -3px;
			left: -3px;
			right: -3px;
			box-shadow: 0 0 0 2px rgba($color-primary, 0.25);
			opacity: 0;
			visibility: hidden;
		}
	}
}
