html {
	font-size: $font-size-base;

	body {
		background: $color-body;
		color: $color-text;
		font-family: $font-standard;
		font-size: $font-size-standard;
		font-weight: $font-weight-regular;
		line-height: $line-height-base;
		margin: 0;
		overflow-x: hidden;
		padding-top: 125px;

		@include mq(tablet-down) {
			padding-top: 61px;
		}

		&.no-scroll {
			overflow: hidden;
		}

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -140px; // Usually height of the header
		}

		.screenreader-text {
			color: $color-white;
			font-size: $font-size-med !important;
			height: 35px !important;
			left: 50%;
			max-width: 225px !important;
			opacity: 0;
			overflow: hidden;
			position: absolute !important;
			top: 5px;
			transform: translateX(-50%);
			width: auto;
			z-index: -1;

			&:focus {
				opacity: 1;
				z-index: 99;
			}
		}
	}

	* {
		box-sizing: border-box;
		min-height: 0;
		min-width: 0;
	}

	.grecaptcha-badge {
		display: none !important;
	}
}
