#about {
	@import "partials/section-intro-block";
	@import "partials/section-block-with-image";
	@import "partials/section-cards";

	header.page-header {
		@extend %blue-header;
	}

	.section-what-we-offer {
		background-color: $color-neutral;
		padding: 55px 0 85px;

		@include mq(tablet-down) {
			padding: 25px 0 45px;
		}

		.sub-content {
			@extend %container;

			& > h2 {
				@extend %common-heading;

				margin-bottom: 50px;
				text-align: center;

				@include mq(tablet-down) {
					margin-bottom: 15px;
				}

				&:after {
					content: none;
				}
			}

			.simple-cards {
				@extend %flex-row;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.card {
					background-color: $color-white;
					flex: 1;
					padding: 42px 40px;
					text-align: center;

					@include mq(tablet-down) {
						margin-bottom: 18px;
						padding: 36px 40px;
					}

					&:nth-child(2) {
						margin: 0 45px;

						@include mq(tablet-down) {
							margin: 0 0 18px;
						}
					}

					.icon {
						@extend %flex-center;

						margin-bottom: 30px;
						min-height: 50px;

						@include mq(tablet-down) {
							margin-bottom: 20px;
							min-height: 42px;
						}

						& > img {
							min-height: 35px;
						}
					}

					.title,
					p {
						color: $color-text;
						font-size: $font-size-large;
						line-height: 29px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 27px;
						}
					}

					.title {
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}

	.section-strapline {
		@extend %swish-1_light;

		background-color: $color-neutral-dark;
		overflow: hidden;
		padding: 75px 0;

		@include mq(tablet-down) {
			padding: 65px 0;

			&:before {
				background-size: cover;
				max-width: 245px;
				min-height: 450px;
				opacity: 0.25;
				right: -39px;
				top: -210px;
			}
		}

		.sub-content {
			@extend %container;

			text-align: center;

			h3 {
				@extend %common-heading;

				color: $color-white;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: $font-size-xxxlarge;
				}

				&:after {
					content: none;
				}
			}

			.copy {
				margin: 0 auto;
				max-width: 991px;
				width: 100%;

				@include mq(tablet-down) {
					padding: 0 35px;
				}

				h4 {
					color: $color-white;
					font-size: $font-size-xxxlarge;
					line-height: 43px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
						line-height: 33px;
					}
				}
			}
		}
	}
}
