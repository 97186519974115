#forms {
	@import "partials/section-tabs";
	@import "partials/section-accordion";

	.section-hero {
		@extend %flex-align-center;

		min-height: 240px;
		position: relative;

		@include mq(mobile) {
			min-height: 200px;
		}

		.hero-image {
			@extend %image-cover;
		}

		.sub-content {
			@extend %container, %z-index-9;

			h1 {
				color: $color-white;

				@include mq(mobile) {
					font-size: $font-size-h3;
					line-height: 37px;
				}
			}

			.copy {
				max-width: 956px;
				width: 100%;

				p {
					color: $color-white;

					font-size: $font-size-xxlarge;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-standard;
						line-height: 23px;
					}
				}
			}
		}
	}

	.section-forms-and-downloads {
		@extend %swish-1_large;

		background: $color-neutral;
		padding-bottom: 345px;

		@include mq(mobile) {
			padding-bottom: 0;
			position: relative;

			&:before {
				display: none;
			}
		}

		.sub-content {
			@extend %container;

			@include mq(mobile) {
				opacity: 0;
				left: 0;
				max-width: none;
				position: absolute;
				top: 0;
				transition: opacity 0.3s ease;
				width: 100%;
				z-index: -1;

				&.mod-show {
					opacity: 1;
					z-index: 9;
				}
			}

			h3.js-title,
			h4.files_title {
				@include mq(mobile) {
					font-size: $font-size-larger;
					line-height: 23px;
					margin-top: 35px;
					text-align: center;
				}
			}

			.nav-back {
				@extend %flex-align-center;

				background: $color-neutral-dark;
				min-height: 41px;

				span {
					@extend %flex-align-center;

					color: $color-white;
					cursor: pointer;
					display: block;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;
					text-align: center;
					width: 100%;
				}

				&.mod_second {
				}

				&.mod-hide {
					display: none;
				}
			}

			.no-message {
				padding: 40px 18px 100px;

				@include mq(mobile) {
					padding: 40px 0 0;
				}

				h4 {
					font-size: $font-size-larger;
					text-align: center;
				}
			}

			#accordion {
				background: $color-white;
				max-width: 1031px;
				width: 100%;

				.accordion-item {
					border-radius: 0;
					margin-bottom: 0;

					&:last-of-type {
						h3 button {
							border-bottom: none;
						}
					}

					h3 {
						button {
							border-bottom: 1px solid #d8d8d8;
							min-height: 82px;
							padding: 0 20px 0 40px;

							&.active {
								border-bottom: none;
							}

							svg.arrow-right {
								left: 20px;
								right: auto;
								width: 7px;
							}

							.num {
								color: rgba(#868585, 0.9);
								font-size: $font-size-large;
								margin-left: auto;
							}
						}
					}

					.accordion-content {
						&.active {
							padding: 0 18px 30px;
						}

						.file {
							@extend %flex-align-center;

							border-bottom: 1px solid $color-borders-light;
							color: rgba($color-link, 0.9);
							font-size: $font-size-large;
							font-weight: $font-weight-regular;
							height: 53px;
							line-height: 25px;

							&:last-of-type {
								border-bottom: none;
							}

							img {
								margin-right: 11px;
							}

							.file_size {
								color: #b1b1b1;
								font-size: $font-size-small-med;
								margin-left: auto;
							}
						}
					}
				}
			}
		}

		&.mod-desktop {
			@include mq(mobile) {
				display: none;
			}
		}

		&.mod-mobile {
			display: none;

			@include mq(mobile) {
				display: block;
			}
		}
	}
}
