#home {
	@import "partials/section-hero";
	@import "partials/section-block-with-image";
	@import "partials/section-cards";

	padding-top: 0;

	@include mq(mobile) {
		overflow-x: hidden;
	}

	.header-main {
		background: transparent;
		border: none;

		@include mq(tablet-down) {
			&.mod-active {
				background: $color-white;
				z-index: 99;
			}
		}

		.sub-container {
			.strapline span {
				color: $color-white;
			}

			.mob-hamburger {
				span {
					color: $color-white;
				}

				.burger-lines .mob-bar {
					background-color: $color-white;
				}

				&.mod-active {
					span {
						color: $color-primary;
					}

					.burger-lines .mob-bar {
						background-color: $color-primary;
					}
				}
			}

			#nav ul.list-nav li {
				& > a {
					color: $color-white;

					@include mq(tablet-down) {
						color: $color-primary;
					}
				}

				&.mod-active a {
					color: $color-primary;

					@include mq(tablet-down) {
						color: $color-black !important;
					}
				}
			}
		}
	}

	.section-intro-content {
		padding: 120px 0 140px;

		@include mq(tablet-down) {
			padding: 40px 0;
		}

		.sub-content {
			@extend %container;

			.two-col {
				@extend %flex-row;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.col {
					flex: 1;

					p {
						font-size: $font-size-larger;
						line-height: 33px;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
							line-height: 30px;
						}
					}

					&.mod-large-text {
						max-width: 470px;
						margin-right: 93px;
						width: 100%;

						h2 {
							font-size: $font-size-xxxlarge;
							line-height: 43px;

							@include mq(tablet-down) {
								color: rgba(#353434, 0.9);
								font-size: $font-size-larger;
								line-height: 27px;
							}
						}
					}
				}
			}
		}
	}

	.section-block-with-image.mod-grey {
		@extend %swish-1;

		@include mq(mobile) {
			&:before {
				display: none;
			}
		}
	}

	.section-cards {
		overflow: hidden;
	}
}
