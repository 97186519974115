.header-main {
	@extend %z-index-9;

	align-items: center;
	background: $color-white;
	border-bottom: 1px solid $color-neutral;
	display: flex;
	height: 125px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	@include mq(tablet-down) {
		height: 61px;
	}

	&.mod-active {
		.sub-container {
			.mob-hamburger {
				z-index: 9999;

				span {
					@include mq(tablet-down) {
						color: $color-primary;
					}
				}

				.burger-lines {
					.mob-bar {
						@include mq(tablet-down) {
							background: $color-primary;
						}
					}
				}
			}

			.sub-logo {
				z-index: 9999;
			}
		}
	}

	.sub-container {
		@extend %flex-row;

		padding: 0 35px;
		width: 100%;

		@media screen and (max-width: 1250px) {
			padding: 0 25px;
		}

		@include mq(tablet-down) {
			padding: 0 15px;
		}

		.sub-logo {
			height: 83px;

			@media screen and (max-width: 1250px) {
				height: 60px;
			}

			@include mq(tablet-down) {
				height: 42px;
			}

			.logo {
				display: block;
				height: 83px;

				@media screen and (max-width: 1250px) {
					height: 60px;
				}

				@include mq(tablet-down) {
					height: 42px;
				}

				img {
					@media screen and (max-width: 1250px) {
						height: 60px;
					}

					@include mq(tablet-down) {
						height: 42px;
					}
				}
			}
		}

		.strapline {
			@extend %flex-align-center;

			margin-left: 20px;

			@media screen and (max-width: 1425px) {
				max-width: 250px;
			}

			@media screen and (max-width: 1200px) {
				max-width: 210px;
			}

			@include mq(mobile) {
				margin-left: 8px;
			}

			span {
				color: $color-primary;
				font-size: $font-size-med;
				font-style: italic;
				font-weight: $font-weight-regular;
				line-height: 33px;

				@media screen and (max-width: 1425px) {
					line-height: 28px;
				}

				@media screen and (max-width: 1380px) {
					max-width: 170px;
				}

				@media screen and (max-width: 1200px) {
					font-size: $font-size-standard;
					line-height: 20px;
				}

				@media screen and (max-width: 1200px) {
					font-size: $font-size-small;
					max-width: 140px;
				}
				

				@include mq(mobile) {
					font-size: $font-size-small;
				}
				
			}

		}

		.mob-hamburger {
			@extend %flex-row, %flex-align-center;

			background: none;
			border: none;
			cursor: pointer;
			display: none;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);

			@include mq(tablet-down) {
				display: flex;
			}

			span {
				color: $color-primary;
				font-family: $font-secondary;
				font-size: $font-size-med;
				margin-right: 12px;
			}

			.burger-lines {
				align-self: center;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				height: 22px;
				justify-content: center;
				overflow: hidden;
				position: relative;
				transition: width 0.5s ease;
				width: 25px;
				z-index: 999;

				.mob-bar {
					background: $color-primary;
					display: block;
					height: 2px;
					position: absolute;
					transform: rotate(0deg);
					transition: 0.5s ease;
					width: 100%;

					&.mob-bar-1 {
						top: 0;
					}

					&.mob-bar-2 {
						top: 10px;
						transition: 0.2 ease;
					}

					&.mob-bar-3 {
						top: 19px;
					}
				}
			}

			&.mod-active {
				.burger-lines {
					.mob-bar-1 {
						top: 10px;
						transform: rotate(135deg);
					}

					.mob-bar-2 {
						width: 0%;
					}

					.mob-bar-3 {
						transform: rotate(-135deg);
						top: 10px;
						width: 100%;
					}
				}
			}
		}

		#nav {
			@extend %flex-align-center;

			margin-left: auto;

			@include mq(tablet-down) {
				align-items: flex-start;
				background: $color-white;
				display: none;
				height: 100vh;
				justify-content: center;
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: opacity 0.3s ease;
				width: 100%;
				z-index: -999;

				&.mod-show {
					display: flex;
					opacity: 1;
					transition: opacity 0.3s ease;
					z-index: 999;
				}
			}

			ul.list-nav {
				@extend %list-default, %flex-align-center, %swish-1_large;

				&:before {
					background-size: contain;
					bottom: 30px;
					display: none;
					height: 360px;
					max-width: 175px;
					min-height: auto;
					right: -10px;
					top: auto;

					@include mq(tablet-down) {
						display: block;
					}
				}

				@include mq(tablet-down) {
					flex-direction: column;
					padding-top: 120px;
					width: 100%;
				}

				li {
					@include mq(tablet-down) {
						@include transition;
						opacity: 0;
						transition: all 0.3s;
						pointer-events: unset;
						transform: translateY(-40px);
					}

					&.mod-show {
						opacity: 1;
					}

					&:not(:last-child) {
						margin-right: 20px;

						@media screen and (max-width: 1510px) {
							margin-right: 7px;
						}

						@include mq(tablet-down) {
							margin-right: 0;
							width: 100%;
						}
					}

					& > a {
						border-radius: $border-radius;
						color: $color-text;
						font-size: $font-size-large;
						padding: 0px 12px;
						position: relative;
						transition: color 0.3s ease;

						@media screen and (max-width: 1300px) {
							font-size: $font-size-med-large;
							padding: 0 7px;
						}

						@media screen and (max-width: 1250px) {
							font-size: $font-size-small-med;
							padding: 0 7px;
						}

						@media screen and (max-width: 1060px) {
							font-size: $font-size-standard;
						}


						@include mq(tablet-down) {
							font-size: $font-size-xxlarge;
							display: block;
							padding: 10px 0;
							text-align: center;
							width: 100%;
						}

						@include mq(mobile) {
							text-decoration: none;
						}
					}

					&.mod-active,
					&:hover,
					&:focus {
						a {
							color: $color-primary;

							@include mq(tablet-down) {
								color: $color-primary !important;
							}
						}
					}

					&:not(.mod-shop) a {
						&:focus {
							@extend %accessible-focus-btn;
						}
					}

					&.mod-shop {
						@include mq(tablet-down) {
							margin-top: 25px;
						}

						a {
							@extend %flex-center;

							background-color: $color-primary;
							border-radius: $border-radius;
							color: $color-white;
							display: flex;
							height: 48px;
							position: relative;
							width: 112px;

							@media screen and (max-width: 1250px) {
								height: 40px;
								width: 77px;
							}

							@include mq(tablet-down) {
								color: $color-white !important;
								font-size: $font-size-large;
								width: 212px;
							}

							&:focus {
								@extend %accessible-focus-btn;
							}

							img {
								margin-bottom: -2px;
								margin-right: 9px;
							}
						}
					}
				}
			}
		}
	}
}

.section-cookies {
	@extend %container-large;

	align-items: center;
	background: $color-black;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 20px;
	}

	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-regular;
				margin-bottom: 0;

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}

			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background 0.3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}

				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}
