#holding-page {
	background: $color-neutral;
	position: relative;
	height: 100%;

	.section-content {
		position: absolute;
		margin: 0 auto;
		left: 0;
		right: 0;
		width: 90%;
		max-width: 640px;
		text-align: center;
		padding: 45px 0 55px;
		background: $color-white;
		transform: translateY(50%);
		box-shadow: 0 0 30px 0 rgba($color-black, 0.25);

		@include mq(mobile) {
			transform: translateY(30%);
			padding: 40px 0 35px;
		}


		h1 {
			margin: 0;
			height: 0;
			position: absolute;
			text-indent: -9999px;
		}

		h2 {
			font-size: $font-size-larger;
			font-weight: $font-weight-light;
			margin: 0 0 40px;
			letter-spacing: 2px;
			text-transform: uppercase;

			span {
				@include mq(mobile) {
					display: block;
				}
			}
		}

		.sub-logo {
			margin: 0 0 40px;
		}

		ul.list-info {
			@extend %list-default;

			font-size: 0;

			li {
				display: inline-block;
				font-size: $font-size-base;

				@include mq(mobile) {
					display: block;
				}


				a {
					font-size: $font-size-med;
				}

				.svg-icon {
					margin: 0 8px 0 0;
				}

				&:first-child {
					margin: 0 30px 0 0;

					@include mq(mobile) {
						margin: 0 0 20px;
					}
				}
			}
		}
	}
}
