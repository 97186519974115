// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%accessible-focus-btn {
	position: relative;

	&:before {
		border: 2px solid $color-primary;
		border-radius: $border-radius * 2;
		bottom: -6px;
		content: "";
		display: block;
		left: -6px;
		position: absolute;
		right: -6px;
		top: -6px;
	}

	&-lighter {
		@extend %accessible-focus-btn;

		&:before {
			border-color: $color-white;
			box-shadow: 0 0 0 4px rgba($color-black, 0.25);
		}
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%image {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&-cover {
		@extend %image;

		object-fit: cover;

		@include mq(tablet-down) {
			object-position: top center;
		}
	}

	&-contain {
		@extend %image;

		object-fit: contain;
	}
}

%heading-line-under {
	position: relative;

	&:after {
		content: "";
		background-color: $color-neutral-dark;
		bottom: -20px;
		height: 2px;
		position: absolute;
		left: 0;
		width: 17px;
	}

	&-white {
		@extend %heading-line-under;

		&:after {
			background-color: $color-white;
		}
	}
}

%z-index {
	position: relative;

	&-9 {
		@extend %z-index;

		z-index: 9;
	}
}

%common-heading {
	@extend %heading-line-under;

	font-size: $font-size-h1;
	font-weight: $font-weight-regular;
	margin-bottom: 16px;

	@include mq(tablet-down) {
		font-size: $font-size-xxxlarge;
		line-height: 65px;
	}

	&:after {
		bottom: -15px;

		@include mq(tablet-down) {
			bottom: -3px;
		}
	}

	&-smaller {
		@extend %common-heading;

		font-size: $font-size-xxxxlarge;
	}
}

%blue-header {
	@extend %flex-align-center;

	background-color: $color-primary;
	height: 125px;

	@include mq(tablet-down) {
		height: 48px;
	}

	.sub-content {
		@extend %container;

		h1 {
			color: $color-white;
			font-size: $font-size-hero-med;
			font-weight: $font-weight-medium;
			line-height: 65px;
			margin-bottom: 0;

			@include mq(tablet-down) {
				font-size: $font-size-h3;
				font-weight: $font-weight-regular;
			}
		}
	}
}

%swish {
	&-1 {
		position: relative;

		&:before {
			content: "";
			background-image: url("../assets/images/swish-1.png");
			background-position: left top;
			background-repeat: no-repeat;
			display: block;
			min-height: 1094px;
			max-width: 575px;
			position: absolute;
			right: -280px;
			top: -240px;
			width: 100%;

			@include mq(tablet-down) {
				right: -410px;
				top: -160px;
			}

			@include mq(mobile) {
				right: -240px;
			}
		}
	}

	&-1_large {
		@extend %swish-1;

		&:before {
			background-image: url("../assets/images/swish-1-large.png");
			max-width: 360px;
			min-height: 768px;
			right: 0;
			top: 20px;
		}
	}

	&-1_light {
		@extend %swish-1;

		&:before {
			background-image: url("../assets/images/swish-1-light.png");
			max-width: 323px;
			min-height: 594px;
			right: -10px;
			top: -270px;
		}
	}

	&-3 {
		position: relative;

		&:before {
			content: "";
			background-image: url("../assets/images/swish-3.png");
			background-position: left top;
			background-repeat: no-repeat;
			display: block;
			min-height: 566px;
			max-width: 611px;
			position: absolute;
			right: 0px;
			top: 391px;
			width: 100%;
		}
	}
}

%aside-testimonials {
	margin-left: auto;
	max-width: 475px;
	width: 100%;

	@include mq(tablet-down) {
		margin: 40px auto 0;
	}

	.image {
		height: 448px;
		margin-bottom: 35px;
		position: relative;

		@include mq(tablet-down) {
			height: 284px;
		}

		img {
			@extend %image-cover;

			border-radius: $border-radius;
		}
	}

	.testimonial {
		@include mq(tablet-down) {
			margin-top: 60px;
		}

		p {
			color: $color-primary;
			font-size: 25 / $font-base * 1rem;
			font-weight: $font-weight-light;
			line-height: 41px;
			margin-bottom: 20px;

			@include mq(tablet-down) {
				font-size: $font-size-xxlarge;
				line-height: 30px;
				text-align: center;
			}
		}

		.testimonial-name {
			color: #182547;
			font-size: $font-size-large;
			line-height: 34px;

			@include mq(tablet-down) {
				display: block;
				font-size: $font-size-med;
				text-align: center;
			}
		}
	}
}

// Flex Placeholders

%flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

%flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

%flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

%flex-space-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

%flex-space-between {
	display: flex;
	justify-content: space-between;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

%flex-grow {
	display: flex;
	flex: 1 0 auto;
}

%flex-row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

%flex-align-center {
	display: flex;
	align-items: center;
}

%flex-align-center-column {
	display: flex;
	justify-content: center;
	justify-self: center;
}
