.footer-main {
	background: $color-secondary;
	padding: 60px 0;
	position: relative;
	z-index: 1;

	@include mq(tablet-down) {
		padding: 35px 0;
	}

	.sub-content {
		@extend %container;

		.footer-top {
			@extend %flex-row;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.footer-info-col {
				@include mq(tablet-down) {
					display: flex;
					flex-direction: column;
				}

				a.btn {
					font-size: $font-size-xxlarge;
					max-width: 172px;
					margin-bottom: 50px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						height: 35px;
						margin-bottom: 25px;
						max-width: 137px;
						order: -1;
					}
				}

				span {
					color: $color-white;
					display: block;
					font-weight: $font-weight-bold;
					font-size: $font-size-large;
					line-height: 16px;
				}

				a[href^="tel:"] {
					color: $color-white;
					display: block;
					font-size: $font-size-h2;
					line-height: 16px;
					margin-top: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-xxxxlarge;
						margin-top: 25px;
						margin-bottom: 60px;
					}
				}

				.address {
					margin-bottom: 20px;
					margin-top: 40px;

					@include mq(tablet-down) {
						display: none;
					}

					p {
						color: rgba($color-white, 0.61);
						font-size: $font-size-large;
						line-height: 29px;
					}
				}

				.socials {
					margin-bottom: 30px;
				}
			}

			.footer-links {
				@extend %flex-row;

				margin-left: auto;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-left: 0;
				}

				ul.list-footer-links {
					@extend %list-default, %flex-column;

					margin-right: 110px;

					@include mq(tablet-down) {
						width: 100%;
						margin-bottom: 50px;
						margin-right: 0;
					}

					&:last-of-type {
						margin-right: 0;

						@include mq(tablet-down) {
							margin-top: 45px;
							width: 100%;
						}
					}

					& > li {
						margin-bottom: 15px;

						@include mq(tablet-down) {
							margin-bottom: 28px;
						}

						&:last-of-type {
							margin-bottom: 0;
						}

						&.link-title {
							@extend %heading-line-under;

							color: $color-white;
							font-size: $font-size-large;
							line-height: 24px;
							margin-bottom: 37px;
							position: relative;

							@include mq(tablet-down) {
								font-size: $font-size-med-large;
								line-height: 16px;
							}
						}

						a {
							color: $color-white;
							display: block;
							font-size: $font-size-large;
							line-height: 30px;
							transition: color 0.3s ease;
							position: relative;

							@include mq(tablet-down) {
								font-size: $font-size-small-med;
								line-height: 13px;
							}

							&:hover,
							&:focus {
								color: $color-link;
							}

							&:focus {
								@extend %accessible-focus-btn;

								&:before {
									bottom: -5px;
									top: -2px;
								}
							}
						}
					}
				}

				.list-socials {
					@extend %list-default, %flex-row;

					@include mq(tablet-down) {
						margin-bottom: 30px;
						order: -1;
					}

					& > li:not(:last-of-type) {
						margin-right: 32px;

						@include mq(tablet-down) {
							margin-right: 25px;
						}
					}

					img {
						@include mq(tablet-down) {
							transform: scale(0.8);
						}
					}
				}
			}
		}

		.footer-bottom {
			margin-top: 20px;

			@include mq(tablet-down) {
				margin: 30px 0 0;
			}

			span {
				color: rgba($color-white, 0.44);
				font-size: $font-size-small;
				line-height: 28px;

				@include mq(tablet-down) {
					font-size: $font-size-tiny;
				}
			}
		}
	}
}
