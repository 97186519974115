.section-banner {
	@extend %flex-align-center;

	background-color: $color-neutral;
	min-height: 206px;

	@include mq(tablet-down) {
		min-height: auto;
	}

	&.mod-blue {
		background-color: $color-primary;

		.sub-content .banner-items .item {
			@include mq(tablet-down) {
				flex-direction: column;
				margin-bottom: 35px;
				width: 100%;

				&:nth-last-of-type(2) {
					margin-bottom: 35px;
				}
			}

			.image-wrap {
				@include mq(tablet-down) {
					max-width: none;
					min-width: auto;
					margin: 0 auto 10px;
				}
			}

			.copy {
				@include mq(tablet-down) {
					padding-right: 0;
					width: 100%;
				}

				h3,
				p {
					color: $color-white;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 27px;
						text-align: center;
					}
				}
			}
		}
	}

	.sub-content {
		@extend %container;

		@include mq(tablet-down) {
			padding: 27px 0;
		}

		.banner-items {
			@extend %list-default, %flex-row, %flex-space-between;

			align-items: flex-start;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.item {
				@extend %flex-row, %flex-start;

				flex: 1;

				@include mq(tablet-down) {
					margin-bottom: 25px;
				}

				&:nth-last-of-type(2) {
					margin: 0 65px;

					@include mq(tablet-down) {
						margin: 0 0 25px;
					}
				}

				.image-wrap {
					margin-right: 20px;
					min-width: 40px;
					padding-top: 3px;

					@include mq(tablet-down) {
						max-width: 32px;
						min-width: 32px;
					}
				}

				.copy {
					@include mq(tablet-down) {
						padding-right: 40px;
					}

					h3,
					p {
						font-size: $font-size-large;
						line-height: 29px;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 30px;
						}
					}

					p {
						font-weight: $font-weight-regular;
					}

					h3 {
						color: $color-primary;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
