#faqs {
	@import "partials/section-accordion";

	.section-faqs {
		@extend %swish-1_large;

		background-color: $color-neutral;
		padding: 65px 0 155px;

		@include mq(tablet-down) {
			padding: 25px 0 45px;

			&:before {
				background-position: 0 0;
				background-size: cover;
				display: block;
				max-width: 190px;
				min-height: 290px;
				right: 0;
				top: 0;
			}
		}

		.sub-content {
			@extend %container;

			.faqs {
				max-width: 790px;
				width: 100%;

				@include mq(tablet-down) {
					max-width: none;
				}

				.faq-section {
					margin-bottom: 60px;

					@include mq(tablet-down) {
						margin-bottom: 35px;
					}

					h1,
					h2 {
						@extend %common-heading;

						margin-bottom: 35px;
					}

					.copy {
						margin-bottom: 50px;

						@include mq(tablet-down) {
							margin-bottom: 20px;
						}

						p {
							font-size: $font-size-xxlarge;
							line-height: 33px;

							@include mq(tablet-down) {
								font-size: $font-size-med-large;
								line-height: 30px;
							}
						}
					}
				}
			}
		}
	}
}
