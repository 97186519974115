ul {
	margin: 0 0 10px 14px;
	padding: 0;

	li {
		margin: 0 0 0 14px;
		padding: 0;
	}
}

ul.slick-dots {
	@extend %list-default;

	display: flex;

	li {
		&:not(:last-child) {
			margin: 0 10px 0 0;
		}

		&.slick-active button {
			opacity: 1;
		}

		button {
			width: 7px;
			height: 7px;
			padding: 0;
			border: none;
			border-radius: 50%;
			background: $color-primary-alt;
			opacity: 0.2;
			text-indent: -9999px;
			outline: none;
			cursor: pointer;
			display: block;
		}
	}
}
