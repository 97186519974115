.js-tabs {
	@extend %list-default;

	display: flex;
	margin-bottom: 35px;
	position: relative;

	@include mq(tablet-down) {
		justify-content: flex-start;
		margin-bottom: 25px;
	}

	&:after {
		background-color: rgba($color-primary, 0.12);
		border-radius: 100px;
		bottom: -3px;
		content: "";
		display: block;
		height: 3px;
		position: absolute;
		width: 100%;

		@include mq(tablet-down) {
			display: none;
		}
	}

	button {
		background-color: transparent;
		border: none;
		color: $color-text-dark;
		cursor: pointer;
		display: block;
		flex: 1;
		font-family: $font-secondary;
		font-size: $font-size-large;
		font-weight: $font-weight-regular;
		height: 33px;
		padding-bottom: 14px;
		text-align: left;

		@include mq(tablet-down) {
			position: relative;
		}

		&:before {
			@include mq(tablet-down) {
				background-color: rgba($color-primary, 0.12);
				border-radius: 100px;
				bottom: -3px;
				content: "";
				display: block;
				height: 3px;
				position: absolute;
				width: 100%;
			}
		}

		&:first-of-type {
			max-width: 193px;

			@include mq(tablet-down) {
				margin-right: 10px;
				max-width: none;
				padding-left: 0;
			}
		}

		&:last-of-type {
			margin-right: 0;

			@include mq(tablet-down) {
				padding-left: 0;
			}

			a {
				padding-left: 4px;
			}
		}

		&.mod-active {
			font-weight: $font-weight-semibold;
			position: relative;

			&:after {
				background-color: $color-primary;
				border-radius: 100px;
				bottom: -3px;
				content: "";
				display: block;
				height: 3px;
				position: absolute;
				width: 100%;
			}
		}

		&.mod-full {
			cursor: default;
			max-width: 100%;
		}
	}

	&.forms-tabs {
		@extend %flex-end;

		background: rgba($color-neutral-dark, 0.89);
		margin-bottom: 0;
		min-height: 84px;

		@include mq(mobile) {
			background: $color-neutral;
			min-height: 650px;
		}

		&:after {
			content: none;
			display: block;
		}

		&.mod-disabled {
			@include mq(mobile) {
				opacity: 0;
				transition: opacity 0.3s ease;
				z-index: -9;
			}
		}

		.tab-wrap {
			@extend %container, %flex-row;

			@include mq(mobile) {
				flex-direction: column;
				left: 0;
				max-width: none;
				padding: 35px 20px 125px;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		button {
			@extend %flex-center;

			background: #efefef;
			color: $color-primary;
			border-radius: 6px 6px 0 0;
			font-size: $font-size-large;
			font-weight: $font-weight-medium;
			height: 61px;
			line-height: 20px;
			margin-right: 5px;
			max-width: 260px;
			padding-bottom: 0;
			text-align: center;
			width: 100%;

			@include mq(mobile) {
				background: $color-white;
				border: none;
				border-radius: 6px;
				height: 46px;
				margin-bottom: 10px;
				max-width: none;
				min-height: 46px;

				&:before {
					content: none;
					display: none;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}

			&.mod-active {
				background: $color-white;
				color: $color-black;

				@include mq(mobile) {
					color: $color-primary;
				}

				&:after {
					content: none;
					display: block;
				}
			}
		}
	}
}

.tab-content {
	display: none;

	@include mq(mobile) {
		padding: 0 23px;
		position: relative;
	}

	.select-dropdown {
		position: relative;

		select {
			@include mq(mobile) {
				appearance: none;
				background: $color-white;
				border-radius: 5px;
				border: 1px solid $color-borders;
				height: 53px;
				font-size: $font-size-large;
				padding: 0 22px;
				width: 100%;
			}

			&.mod-hide {
				opacity: 0;
				pointer-events: none;
			}
		}

		.arrow {
			position: absolute;
			right: 22px;
			top: 15px;

			svg {
				height: 6px;
				width: 12px;
			}
		}
	}

	.files_mobile {
		@extend %flex-column;

		border-radius: 5px;
		opacity: 0;
		position: absolute;
		top: 0;
		width: calc(100% - 46px);
		z-index: -1;

		&.mod-show {
			opacity: 1;
			z-index: 9;
		}

		.files_title {
			text-align: center;
		}

		.files_wrap {
			background: $color-white;
			padding: 0 14px;
		}

		.file {
			@extend %flex-align-center;

			border-bottom: 1px solid rgba($color-borders, 0.57);
			color: $color-primary;
			font-size: $font-size-med;
			font-weight: $font-weight-regular;
			line-height: 21px;
			min-height: 65px;
			padding-right: 30px;
			text-decoration: none;

			&:last-of-type {
				border: none;
			}

			img {
				margin-right: 18px;
			}
		}

		.file_size {
			display: none;
		}
	}

	&.mod-active {
		display: block;
	}
}
