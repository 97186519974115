#training {
	@import "partials/section-intro-block";
	@import "partials/section-banner";

	.section-intro-block {
		@extend %swish-1_large;

		.image {
			@extend %z-index-9;
		}
	}

	.section-courses {
		background-color: $color-neutral;
		padding: 70px 0;

		@include mq(tablet-down) {
			padding: 50px 0 60px;
		}

		.sub-content {
			@extend %container;

			& > h2 {
				@extend %common-heading-smaller;

				margin-bottom: 45px;

				@include mq(tablet-down) {
					text-align: center;

					&:after {
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.cards {
				@extend %flex-row;

				@include mq(mobile) {
					flex-direction: column;
				}

				.card {
					border-radius: $border-radius;

					@include mq(mobile) {
						margin-bottom: 16px;
					}

					&:first-of-type {
						margin-right: 79px;

						@include mq(tablet-down) {
							margin-right: 20px;
						}

						@include mq(mobile) {
							margin-right: 0;
						}
					}

					.image {
						border-radius: $border-radius;
						height: 232px;
						position: relative;

						@include mq(mobile) {
							height: 139px;
						}

						& > img {
							@extend %image-cover;

							border-radius: $border-radius $border-radius 0 0;
						}
					}

					.copy {
						background-color: $color-white;
						border-radius: 0 0 $border-radius $border-radius;
						padding: 37px 32px;

						@include mq(mobile) {
							padding: 20px;
						}

						.title {
							color: $color-text;
							font-size: 28 / $font-base * 1rem;
							padding-right: 120px;

							@include mq(tablet-down) {
								font-size: 26 / $font-base * 1rem;
								padding-right: 0;
							}
						}

						.intro-text {
							p,
							strong {
								color: $color-text;
								font-size: $font-size-large;
								line-height: 29px;
								margin-bottom: 35px;

								@include mq(tablet-down) {
									font-size: $font-size-med;
									line-height: 27px;
								}
							}

							& > p {
								&:first-of-type,
								&:last-of-type {
									margin-bottom: 0;
								}
							}

							strong.subtitle {
								display: block;
								font-weight: $font-weight-bold;
								margin-bottom: 0;
							}

							.hidden-content {
								max-height: 0;
								overflow: hidden;
								transition: all 0.3s ease;

								p {
									&:last-of-type {
										margin-bottom: 0;
									}
								}

								&.mod-hidden-content-visible {
									max-height: 1000px;
									margin: 35px 0 10px;
									overflow: visible;

									& ~ .mod-link {
										margin-bottom: 60px;

										@include mq(tablet-down) {
											margin-bottom: 30px;
										}
									}
								}
							}
						}

						.btn {
							height: 60px;
							width: 100%;

							@include mq(tablet-down) {
								font-size: $font-size-med;
								height: 36px;
								margin-top: 10px;
							}

							&.mod-link {
								height: auto;
								justify-content: flex-start;
								margin: 30px 0;
								max-width: max-content;

								@include mq(tablet-down) {
									font-size: $font-size-med-large;
								}
							}

							&.mod-download {
								color: $color-text;
								display: none;
								font-family: $font-standard;
								font-size: $font-size-larger;
								font-weight: $font-weight-semibold;
								justify-content: flex-start;
								margin-left: 0;
								max-width: none;
								padding: 0 30px 0 20px;

								@include mq(tablet-down) {
									font-size: $font-size-med-large;
									height: 60px;
									padding: 0 16px;
								}

								&:hover {
									color: $color-white;

									svg {
										color: $color-white;
									}

									span {
										color: $color-white;
									}
								}

								span {
									color: #b1b1b1;
									font-size: $font-size-small-med;
									margin-left: auto;
								}

								svg {
									color: $color-primary;
									height: 25px;
									margin-right: 14px;
									width: 20px;

									@include mq(tablet-down) {
										transform: scale(0.9);
									}
								}

								&.mod-show {
									display: flex;
									margin-bottom: 31px;
								}
							}

							&.mod-book {
								max-width: none;
								margin-top: 15px;
							}
						}
					}
				}
			}
		}
	}

	.section-info-block {
		background-color: $color-white;
		padding: 95px 0 155px;

		@include mq(tablet-down) {
			padding: 40px 0;
		}

		.sub-content {
			@extend %container, %flex-row;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			h3.title {
				@extend %common-heading;

				margin-bottom: 40px;

				@include mq(tablet-down) {
					margin-bottom: 30px;
					text-align: center;

					&:after {
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.copy {
				flex: 1;
				margin-right: 93px;

				@include mq(tablet-down) {
					margin-right: 0;
				}

				p {
					color: $color-black;
					font-size: $font-size-large;
					line-height: 33px;
					margin-bottom: 40px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 30px;
						text-align: center;
					}
				}
			}

			.aside-testimonials {
				@extend %aside-testimonials;
			}
		}
	}
}
