.section-intro-block {
	@extend %swish-1_large;

	overflow: hidden;
	padding: 65px 0 125px;
	z-index: 1;

	@include mq(tablet-down) {
		padding: 10px 0 50px;
	}

	&:before {
		display: none;

		@include mq(tablet-down) {
			background-position: 0px -220px;
			background-size: cover;
			display: block;
			max-width: 215px;
			min-height: 270px;
			right: 0;
			top: 0px;
		}
	}

	.sub-content {
		@extend %container, %flex-row, %flex-space-between;

		@include mq(mobile) {
			flex-direction: column;
		}

		h2.title {
			@extend %common-heading;

			margin-bottom: 35px;

			@include mq(mobile) {
				margin-bottom: 25px;
			}

			&.mod-desktop {
				@include mq(mobile) {
					display: none;
				}
			}

			&.mod-mobile {
				display: none;

				@include mq(mobile) {
					display: block;
				}
			}
		}

		.copy {
			max-width: 665px;
			margin-right: 130px;
			width: 100%;

			@include mq(tablet-down) {
				margin-right: 40px;
			}

			@include mq(mobile) {
				margin-right: 0;
				order: 1;
				padding-right: 10px;
				max-width: none;
			}

			p {
				font-size: $font-size-larger;
				line-height: 33px;
				margin-bottom: 45px;

				@include mq(tablet-down) {
					font-size: $font-size-med;
					line-height: 30px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.image {
			border-radius: $border-radius;
			height: 448px;
			max-width: 475px;
			position: relative;
			width: 100%;

			@include mq(tablet) {
				height: 270px;
				max-width: 290px;
				margin-top: 105px;
			}

			@include mq(mobile) {
				height: 294px;
				margin-bottom: 25px;
				max-width: none;
				order: 0;
			}

			img {
				@extend %image-cover;

				border-radius: $border-radius;
			}
		}
	}
}
