.section-hero {
	@extend %flex-row, %flex-align-center;

	height: 100vh;
	position: relative;
	width: 100%;

	@include mq(mobile) {
		align-items: flex-start;
		padding-top: 90px;
	}

	&.mod-desktop {
		@include mq(mobile) {
			display: none;
		}
	}

	&.mod-mobile {
		display: none;

		@include mq(mobile) {
			display: flex;
		}
	}

	.hero-image {
		@extend %image-cover;
	}

	.sub-content {
		@extend %container, %z-index-9;

		h1 {
			@extend %heading-line-under-white;

			color: $color-white;
			font-family: $font-standard;
			font-size: $font-size-hero-large;
			font-weight: $font-weight-semibold;
			line-height: 65px;
			max-width: 530px;
			margin-bottom: 40px;
			padding-right: 150px;
			width: 100%;

			@include mq(mobile) {
				font-size: $font-size-h1;
				line-height: 38px;
				margin-bottom: 25px;

				&:after {
					content: none;
				}
			}
		}

		.copy {
			max-width: 530px;
			width: 100%;

			@include mq(mobile) {
				padding-right: 35px;
			}

			p {
				color: $color-white;
				font-size: $font-size-xxlarge;
				font-weight: $font-weight-regular;
				line-height: 33px;

				@include mq(mobile) {
					font-size: $font-size-med-large;
				}
			}

			a.btn {
				margin-top: 50px;

				@include mq(mobile) {
					margin-top: 20px;
				}
			}
		}
	}
}
