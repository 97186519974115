#contact {
	.section-form {
		@extend %swish-1_large;

		background-color: $color-neutral;
		padding: 80px 0 95px;

		@include mq(tablet-down) {
			padding: 10px 0 45px;

			&:before {
				background-position: 0 0;
				background-size: cover;
				display: block;
				max-width: 190px;
				min-height: 290px;
				right: 0;
				top: 0;
			}
		}

		.sub-content {
			@extend %container, %flex-row;

			max-width: 1100px;
			width: 95%;

			@include mq(tablet-down) {
				flex-direction: column;
			}
		}

		.contact-information {
			margin-right: 123px;
			max-width: 375px;
			width: 100%;

			h1 {
				@extend %common-heading;

				margin-bottom: 50px;

				@include mq(tablet-down) {
					margin-bottom: 25px;
				}
			}

			p {
				font-size: $font-size-h3;
				line-height: 38px;

				@include mq(tablet-down) {
					font-size: $font-size-large;
					line-height: 30px;
				}
			}

			.contact-details {
				margin-bottom: 65px;

				@include mq(tablet-down) {
					margin-bottom: 20px;
				}

				p {
					font-size: $font-size-xxxlarge;

					@include mq(tablet-down) {
						font-size: 22 / $font-base * 1rem;
						line-height: 38px;
					}
				}
			}

			.contact-address {
				@include mq(tablet-down) {
					margin-bottom: 35px;
				}
			}
		}

		#contactForm {
			@extend %container;

			position: relative;
			max-width: 100%;

			@include mq(tablet-down) {
				max-width: 100%;
				width: 100%;
			}

			&.mod-hide {
				display: none;
			}

			p {
				font-size: $font-size-xxlarge;
				line-height: 28px;
				margin-bottom: 35px;

				@include mq(tablet-down) {
					font-size: $font-size-med-large;
					line-height: 30px;
				}
			}

			.loading {
				background: rgba($color-white, 0.5);
				height: 100%;
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: opacity 0.3s ease;
				width: 100%;
				z-index: -1;

				&.mod-show {
					opacity: 1;
					z-index: 9;
				}

				img {
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		.form-thanks {
			@extend %container;

			display: none;
			padding: 0 0 250px;

			&.mod-show {
				display: block;
			}

			h3 {
				font-size: $font-size-h2;
				font-weight: $font-weight-semibold;
				margin-bottom: 10px;
			}

			p {
				font-size: $font-size-large;
				line-height: 28px;
			}
		}
	}

	.section-map {
		#map {
			height: 575px;
		}
	}
}
