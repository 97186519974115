#product-ranges {
	@import "partials/section-cards";
	@import "partials/section-block-with-image";

	.section-intro-text {
		background-color: $color-neutral;
		padding: 85px 0 60px;

		@include mq(tablet-down) {
			padding: 15px 0 30px;
		}

		.sub-content {
			@extend %container;

			h1.title {
				@extend %common-heading;

				margin-bottom: 30px;
			}

			.copy {
				max-width: 846px;
				width: 100%;

				p {
					font-size: $font-size-xxlarge;
					line-height: 33px;

					@include mq(mobile) {
						font-size: $font-size-med-large;
						line-height: 30px;
					}
				}
			}
		}
	}

	.section-cards {
		background-color: $color-neutral;
		padding: 0 0 150px;

		@include mq(tablet-down) {
			padding-bottom: 60px;
		}

		.sub-content .cards {
			margin-top: 0;
		}
	}
}
