nav.nav-breadcrumbs {
	@extend %flex-align-center;

	border-bottom: 1px solid $color-neutral;
	border-top: 1px solid $color-neutral;
	height: 52px;

	@include mq(mobile) {
		display: none;
	}

	.container {
		@extend %container;

		ol.breadcrumbs {
			@extend %list-default, %flex-row;

			& > li {
				&.home {
					@extend %flex-align-center;

					a {
						@extend %flex-align-center;

						svg {
							height: auto;
							margin: 0;
							width: 15px;
						}
					}
				}

				&.current {
					a {
						color: $color-neutral-dark;
						text-decoration: none;
					}
				}

				a {
					color: $color-primary-alt;
					font-family: $font-secondary;
					font-size: $font-size-small-med;
					font-weight: $font-weight-medium;
					text-decoration: underline;
					transition: color 0.3s ease;

					&:hover {
						color: $color-text;
					}
				}

				svg {
					color: $color-neutral-dark;
					height: 9px;
					margin: 0 10px;
					width: 5px;
				}
			}
		}
	}
}
