@import "../contents/partials/section-cards";

.section-cards .sub-content {
	.cards {
		&.mod-std .item-card {
			margin-bottom: 28px;
			margin-right: 28px;
			width: calc(33.33333% - 19px);

			.copy a.mod-link {
				margin-top: 35px;
			}
		}

		&.list-posts {
			@include mq(tablet-down) {
				margin-top: 30px;
			}

			.item-card:not(.slick-slide) {
				.card-link {
					display: none;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 9;

					@include mq(mobile) {
						display: block;
					}
				}

				.copy {
					@include mq(tablet-down) {
						align-items: flex-start;
						flex-direction: column;
						justify-content: flex-start;

						.mod-link {
							margin-top: 30px;
						}
					}

					.title {
						@include mq(tablet-down) {
							font-size: $font-size-xxlarge;
							line-height: 28px;
						}

						@include mq(mobile) {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
