#page {
	.section-content {
		padding: 60px 0 400px;

		@include mq(tablet-down) {
			padding: 20px 0 150px;
		}

		.sub-content {
			@extend %container;

			h1 {
				margin-bottom: 50px;

				@include mq(tablet-down) {
					font-size: $font-size-xxxlarge;
					line-height: 65px;
					margin-bottom: 10px;
				}
			}

			.copy {
				max-width: 950px;
				width: 100%;

				h2,
				h3,
				h4 {
					font-size: $font-size-h3;
					margin-bottom: 10px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: 32px;
				}
			}
		}
	}
}
