#news {
	.section-news-list {
		@extend %swish-1_large;

		background-color: $color-neutral;
		padding: 65px 0 95px;

		@include mq(tablet-down) {
			padding: 25px 0 45px;
		}

		@include mq(tablet-down) {
			&:before {
				background-position: 0 0;
				background-size: cover;
				display: block;
				max-width: 190px;
				min-height: 290px;
				right: 0;
				top: 0;
			}
		}

		.sub-content {
			@extend %container;

			h1 {
				@extend %common-heading;

				margin-bottom: 80px;

				@include mq(tablet-down) {
					margin-bottom: 0;
				}
			}
		}
	}
}
