#news-post {
	@import "../contents/partials/section-breadcrumbs";
	@import "../contents/partials/section-cards";

	.section-post {
		padding: 55px 0 180px;

		@include mq(tablet-down) {
			padding: 55px 0;
		}

		@include mq(mobile) {
			padding: 15px 0 55px;
		}

		.sub-content {
			@extend %container, %flex-row;

			@include mq(mobile) {
				flex-direction: column;
			}

			.news-title {
				padding-right: 100px;
				margin-bottom: 70px;

				@include mq(tablet-down) {
					margin-bottom: 20px;
				}

				&.mod-desktop {
					@include mq(mobile) {
						display: none;
					}
				}

				&.mod-mobile {
					display: none;

					@include mq(mobile) {
						display: flex;
					}
				}

				@include mq(tablet-down) {
					font-size: $font-size-xxxlarge;
					line-height: 37px;
					padding-right: 0;
				}
			}

			.copy {
				max-width: 716px;
				margin-right: 90px;
				padding-right: 10px;
				width: 100%;

				@include mq(tablet) {
					margin-right: 50px;
				}

				@include mq(mobile) {
					order: 1;
					margin-right: 0;
				}

				p {
					font-size: $font-size-larger;
					line-height: 33px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 30px;
					}
				}

				iframe {
					max-width: 100%;
				}
			}

			.image {
				border-radius: $border-radius;
				height: 448px;
				margin-left: auto;
				position: relative;
				max-width: 475px;
				width: 100%;

				@include mq(tablet-down) {
					height: 33vw;
					width: 65vw;
				}

				@include mq(mobile) {
					margin-bottom: 30px;
					height: auto;
					max-height: 448px;
					min-height: 188px;
					width: 100%;
					margin-left: 0;
				}

				& > img {
					@extend %image-cover;

					border-radius: $border-radius;
				}

				.carousel-image {
					& > img {
						max-width: 100%;
					}
				}

				.carousel-images-wrap {
					height: 55vw;
					max-height: 448px;
					min-height: 188px;

					.slick-list {
						height: 55vw;
						max-height: 448px;
						min-height: 188px;
					}
				}

				.slick-dots {
					margin-top: 10px;

					li {
						// @see main.js
						width: 0;
						margin: 0;
						position: relative;

						button {
							width: 0;
							height: 4px;
							padding: 0;
							border: none;
							outline: none;
							border-radius: 0;
							background-color: $color-primary;
							opacity: 1;

							// @see main.js this is a clone of the original
							&.js-clone {
								position: absolute;
								top: 0;
								left: 0;
								background-color: $color-neutral;
								width: 100%;
							}

							&:not(.js-clone) {
								z-index: 1;
								position: relative;
							}
						}
					}
				}
			}
		}
	}

	.section-related {
		overflow: hidden;
		padding: 110px 0 130px;

		header {
			h2 {
				@extend %common-heading, %heading-line-under-white;

				color: $color-white;
			}
		}
	}
}
