.section-cards {
	overflow: visible;
	padding: 135px 0;
	position: relative;

	@include mq(tablet-down) {
		padding: 45px 0 85px;
	}

	&.mod-blue {
		background-color: $color-primary;

		@include mq(tablet-down) {
			overflow: hidden;
			padding: 40px 0 80px;

			.swish {
				display: none;
			}
		}
	}

	&.mod-gallery {
		@include mq(tablet-down) {
			.swish {
				display: block;
			}
		}
	}

	.swish {
		position: absolute;
		right: -290px;
		top: 24%;
		z-index: 0;

		@include mq(tablet-down) {
			right: -220px;
			top: 16%;
		}
	}

	.sub-content {
		@extend %container;

		header {
			@extend %flex-align-center;

			h3 {
				@extend %common-heading, %heading-line-under-white;

				color: $color-white;
			}

			.nav-slider {
				@extend %list-default, %flex-row;

				margin-left: auto;

				button {
					@extend %z-index-9;

					background: none;
					border: none;
					cursor: pointer;

					&:focus {
						@extend %accessible-focus-btn-lighter;

						svg {
							color: $color-white;
						}
					}

					&.slick-disabled,
					&.slick-hidden {
						cursor: default;
						transition: opacity 0.3s ease;

						svg {
							opacity: 0.33;
						}

						&:focus {
							&:before {
								border: none;
								box-shadow: none;
								outline: none;
							}
						}
					}

					&.prev {
						margin-right: 20px;

						@include mq(tablet-down) {
							margin-right: 15px;
						}
					}

					svg {
						color: $color-white;
						height: 22px;
						transition: opacity 0.3s ease;
						width: 12px;

						@include mq(tablet-down) {
							height: 16px;
							width: 10px;
						}
					}
				}
			}
		}

		.cards {
			overflow: visible;
			margin-top: 45px;

			&.mod-std {
				@extend %flex-row, %flex-wrap;

				@include mq(mobile) {
					flex-direction: column;
				}

				.item-card:not(.slick-slide) {
					outline: none;
					margin: 0 19px 16px 0;
					width: calc(100% / 3 - 13px);

					@include mq(tablet-down) {
						margin-bottom: 19px;
						width: calc(100% / 2 - 10px);

						&:nth-child(2n) {
							margin-right: 0;
						}
					}

					@include mq(mobile) {
						width: 100%;
					}

					&:nth-child(3n) {
						margin-right: 0;

						@include mq(tablet-down) {
							margin-right: 19px;

							&:nth-child(2n) {
								margin-right: 0;
							}
						}
					}

					.card-mobile-link {
						display: none;
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 9;

						@include mq(mobile) {
							display: block;
						}
					}

					.copy {
						@include mq(mobile) {
							align-items: center;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
						}

						.title {
							margin-bottom: 10px;

							@include mq(mobile) {
								font-size: $font-size-xxlarge;
								margin-bottom: 0;
							}

							a {
								@include mq(mobile) {
									text-decoration: underline;
								}
							}
						}

						.mod-link {
							@include mq(tablet-down) {
								font-size: $font-size-med;
								margin-top: 0;
							}
						}

						.title,
						.mod-link {
							@include mq(tablet-down) {
								line-height: 30px;
							}

							@include mq(mobile) {
								text-decoration: underline;
							}
						}
					}

					&:focus {
						@extend %accessible-focus-btn;
					}
				}
			}

			.slick-list {
				overflow: visible;
				margin-left: -28px;

				@include mq(tablet-down) {
					max-width: 100%;
				}

				@include mq(mobile) {
					padding-right: 25px;
				}

				.slick-track {
					@extend %flex-row;

					display: flex;
					margin: 0;

					li.item-news {
						height: auto;
					}

					.slick-slide {
						height: inherit;
						margin-left: 28px;
						opacity: 0;
						outline: none;
						position: relative;
						transition: opacity 0.3s ease;

						@include mq(mobile) {
							opacity: 1;
						}

						&:focus {
							@extend %accessible-focus-btn-lighter;
						}

						&.slick-active {
							opacity: 1;
							transition: opacity 0.3s ease;
						}
					}
				}
			}

			.item-card {
				@extend %flex-column;

				border-radius: $border-radius;
				margin: 0;
				position: relative;
				margin-left: 28px;

				&:first-child {
					margin-left: 0;
				}

				.image {
					border-radius: $border-radius $border-radius 0 0;
					height: 215px;
					position: relative;

					@include mq(tablet) {
						height: 170px;
					}

					&.mod-tall {
						height: 350px;

						@include mq(tablet-down) {
							height: 248px;
						}
					}

					img {
						@extend %image-cover;

						border-radius: $border-radius $border-radius 0 0;
					}
				}

				.copy {
					@extend %flex-column, %flex-grow;

					background-color: $color-white;
					border-radius: 0 0 $border-radius $border-radius;
					padding: 27px 25px;

					@include mq(tablet-down) {
						padding: 22px 19px;
					}

					.title {
						color: $color-text;
						font-size: $font-size-h3;
						line-height: 33px;
						margin-bottom: 50px;

						@include mq(tablet-down) {
							font-size: $font-size-xxlarge;
							line-height: 28px;
							margin-bottom: 25px;
						}

						&:hover {
							text-decoration: underline;
						}

						a {
							@include mq(mobile) {
								text-decoration: underline;
							}
						}
					}

					a.mod-link {
						font-size: $font-size-large;
						font-weight: $font-weight-medium;
						display: inline-flex;
						height: auto;
						justify-content: flex-start;
						line-height: 24px;
						margin-top: auto;
						max-width: none;
						transition: color 0.3s ease;
						width: max-content;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
						}

						&:hover {
							color: $color-text;
							text-decoration: underline;
						}
					}
				}
			}
		}

		.alt-message {
			font-size: $font-size-h3;
			font-weight: $font-weight-semibold;
		}
	}

	#product-ranges.sub-category & {
		.sub-content .cards.mod-std .item-card:not(.slick-slide) .copy {
			@include mq(tablet-down) {
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;

				.mod-link {
					margin-top: 10px;

					@include mq(mobile) {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
