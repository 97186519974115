#careers {
	section.section-careers {
		@extend %swish-1_large;

		background-color: $color-neutral;
		padding: 65px 0 95px;
		position: relative;

		@include mq(tablet-down) {
			padding: 25px 0 45px;
		}

		@include mq(tablet-down) {
			padding: 22px 0;
		}

		h1 {
			@extend %heading-line-under;

			margin-bottom: 50px;
		}

		.sub-content {
			@extend %container;

			.careers {
				position: relative;
				z-index: 99;
			}

			img {
				max-width: 100%;
			}

			h1 {
				@include mq(tablet-down) {
					font-size: $font-size-h2;
					line-height: 37px;
				}
			}
		}

		select#location,
		select#department {
			min-height: 35px;
			background-repeat: no-repeat;
			background-position: right;
			background-size: 18px;
			padding-right: 20px;
		}

		select#location {
			margin-bottom: 28px;
		}

		.mod-sr-only {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			margin: -1px;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			border: 0;
		}

		.job-list {
			margin: 0 !important;
			padding: 0 !important;

			li {
				list-style-type: none;
				cursor: pointer;
				background: white;
				margin-left: 0;
				margin-bottom: 15px;
				padding: 5px 10px;
				border-radius: 5px;

				&.mod-active {
					border: 2px solid #0ca6c8;
				}

				.blue-btn {
					display: inline-block;
					background: #1569ae;
					color: #fff;
					font-size: 18px;
					padding: 5px 12px;
					border-radius: 5px;
					margin-bottom: 20px;
				}
			}

			.details {
				display: none;
				padding-top: 8px;
			}

			h3 {
				margin-bottom: 0;
			}

			hr {
				border-bottom: 1px dotted #333;
			}

			p,
			font {
				font-size: $font-size-base;
			}

			strong font {
				font-size: $font-size-large;
				font-weight: $font-weight-bold;
			}
		}
	}

	.section-apply {
		background: $color-white;
		padding: 95px 0 160px;

		@include mq(tablet-down) {
			padding-bottom: 100px;
		}

		.sub-content {
			@extend %container;

			h2 {
				@extend %common-heading;

				margin-bottom: 40px;
			}

			.copy {
				@extend %container-small;

				margin: 0;

				&:first-of-type {
					margin-bottom: 50px;
				}

				&:last-of-type {
					margin-top: 50px;
				}

				p {
					font-size: $font-size-larger;
					line-height: 32px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
						line-height: 30px;
					}
				}
			}

			.btns {
				@extend %flex-row;

				@include mq(tablet-down) {
					flex-direction: column;
					display: flex;
				}

				@include mq(mobile) {
					align-items: center;
				}

				.btn {
					max-width: none;
					padding: 0 35px;
					width: auto;

					@include mq(tablet-down) {
						height: 40px;
						max-width: 351px;
						width: 100%;
					}

					&:first-of-type {
						margin-right: 50px;

						@include mq(tablet-down) {
							margin-right: 0;
							margin-bottom: 30px;
						}
					}
				}
			}
		}
	}
}
