.section-block-with-image {
	background-color: $color-white;
	padding: 117px 0;

	@include mq(tablet-down) {
		padding: 40px 0;
	}

	.sub-content {
		@extend %container, %flex-row;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.image {
			max-width: 475px;
			min-width: 475px;
			min-height: 448px;
			position: relative;
			width: 100%;

			@include mq(tablet-down) {
				min-height: 284px;
				margin: 0 auto;
				max-width: 358px;
				min-width: 358px;
				width: 100%;
			}

			@include mq(mobile) {
				max-width: none;
				min-width: auto;
			}

			img {
				@extend %image-cover;

				border-radius: $border-radius;

				@include mq(tablet-down) {
					max-width: 100%;
				}
			}
		}

		.copy {
			@extend %flex-column, %flex-align-center-column;

			margin-left: 100px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 35px;
				text-align: center;
			}

			.title {
				@extend %common-heading;

				margin-bottom: 45px;

				@include mq(tablet-down) {
					line-height: 32px;
				}

				&:after {
					@include mq(tablet-down) {
						left: 50%;
						transform: translateX(-50%);
						bottom: -20px;
					}
				}
			}

			p {
				color: $color-black;
				font-size: $font-size-large;
				line-height: 33px;

				@include mq(tablet-down) {
					font-size: $font-size-med;
					line-height: 30px;
				}
			}

			a.btn {
				margin-top: 50px;

				@include mq(tablet-down) {
					margin: 30px auto 0;
				}
			}
		}
	}

	&.mod-grey {
		background-color: $color-neutral;
	}

	&.mod-reverse {
		@include mq(tablet-down) {
			padding-bottom: 90px;
		}

		.sub-content {
			@extend %flex-row-reverse;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.copy {
				margin-left: 0;
				margin-right: 190px;

				@include mq(tablet-down) {
					margin-right: 0;
				}
			}
		}
	}
}
